<template>
  <v-container class="mt-8 mb-8">
    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
    <v-btn @click="formato()" color="success" text>Exportar Formato</v-btn>
    <v-card>

      <v-simple-table class="elevation-1" fixed-header height="400px">
        <template v-slot:default>
          <thead>

            <tr>
              <th class="text-left">
                id
              </th>
              <th class="text-left">
                Estado
              </th>
              <th class="text-left">
                Cod Barras
              </th>
              <th class="text-left">
                nombre
              </th>
              <th class="text-left">
                categoria
              </th>
              <th class="text-left">
                medida
              </th>
              <th class="text-left">
                stock
              </th>
              <th class="text-left">
                precio
              </th>
              <th class="text-left">
                Margen
              </th>
              <th class="text-left">
                Costo
              </th>
              <th class="text-left">
                cocina
              </th>
              <th class="text-left">
                tipoproducto
              </th>
              <th class="text-left">
                operacion
              </th>
              <th class="text-left">
                icbper
              </th>
              <th class="text-left">
                barra
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.activo }}</td>
              <td>{{ item.codbarra }}</td>
              <td>{{ item.nombre }}</td>
              <td>{{ item.categoria }}</td>
              <td>{{ item.medida }}</td>
              <td>{{ item.stock }}</td>
              <td>{{ item.precio }}</td>
              <td>{{ item.margen }}</td>
              <td>{{ item.costo }}</td>
              <td>{{ item.cocina }}</td>
              <td>{{ item.tipoproducto }}</td>
              <td>{{ item.operacion }}</td>
              <td>{{ item.icbper }}</td>
              <td>{{ item.barra }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>
    <v-btn v-if="tableData != ''" :loading="loading" @click="guardar()" color="success" class="text-center" block>Subir
      tabla</v-btn>
    <v-btn @click="obtenerDatos()" color="success">Ver Data</v-btn>
  </v-container>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel'
import XLSX from 'xlsx'
import {
  nuevoProducto,
  allProductos,
  allMedidas,
  allEmpresas
} from '../db'
export default {
  name: 'UploadExcel',
  components: {
    UploadExcelComponent
  },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      loading: false
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess({
      results,
      header
    }) {
      this.tableData = results
      this.tableHeader = header
    },
    formato() {
      var data = ''
      var formato = [{
        id: '10001',
        activo: 'true',
        codbarra: '',
        nombre: '',
        categoria: '',
        medida: 'UNIDAD',
        stock: '',
        precio: '',
        costo: '',
        tipoproducto: 'BIEN',
        operacion: 'GRAVADA',
        icbper: 'false',
        cocina: 'true',
        barra: 'false',
        cocina2: 'false',
        barra2: 'false',
        controstock: 'true',
        cargoxconsumo: false,
        stock_min: '',
        grupo_obs: ''
      }]
      if (this.tableData != '') {
        data = XLSX.utils.json_to_sheet(this.tableData)
      } else {
        data = XLSX.utils.json_to_sheet(formato)
      }

      const workbook = XLSX.utils.book_new()
      const filename = 'DATA'
      XLSX.utils.book_append_sheet(workbook, data, "datos")
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    guardar() {
      var array = ''
      for (var i = 0; i < this.tableData.length; i++) {
        array = {
          id: this.tableData[i].id,
          activo: this.stringToBoolean(this.tableData[i].activo),
          codbarra: this.tableData[i].codbarra,
          nombre: this.tableData[i].nombre,
          categoria: this.tableData[i].categoria,
          medida: this.tableData[i].medida,
          stock: this.tableData[i].stock,
          precio: this.tableData[i].precio,
          costo: this.tableData[i].costo,
          cocina: this.stringToBoolean(this.tableData[i].cocina),
          barra: this.stringToBoolean(this.tableData[i].barra),
          barra2: this.stringToBoolean(this.tableData[i].barra2),
          cocina2: this.stringToBoolean(this.tableData[i].barra2),
          tipoproducto: this.tableData[i].tipoproducto,
          operacion: this.tableData[i].operacion,
          icbper: this.stringToBoolean(this.tableData[i].icbper),
          controstock: this.stringToBoolean(this.tableData[i].controstock),
          cargoxconsumo: this.stringToBoolean(this.tableData[i].cargoxconsumo),
          stock_min: this.tableData[i].stock,
          grupo_obs: '',
          es_combo: '',
          lista_combo: '',
        }
        if (nuevoProducto(this.tableData[i].id, array)) {
          console.log((100 / this.tableData.length) * (i + 1))
        }

      }
      this.tableData = []
      //this.$router.push({name:"productos"})
    },
    stringToBoolean(string) {
      switch (string.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
          return true;
        case "false":
        case "no":
        case "0":
        case null:
          return false;
        default:
          return Boolean(string);
      }
    },
    obtenerDatos() {
      allProductos().once("value").then((snapshot) => {
        let array = [];
        snapshot.forEach((item) => {
          let data = item.val();
          array.push({
            id: data.id,
            activo: data.activo.toString(),
            codbarra: data.codbarra,
            nombre: data.nombre,
            categoria: data.categoria,
            medida: data.medida,
            stock: data.stock,
            precio: data.precio,
            costo: data.costo,
            tipoproducto: data.tipoproducto,
            operacion: data.operacion,
            icbper: data.icbper.toString(),
            cocina: data.cocina.toString(),
            barra: data.barra.toString(),
            cocina2: data.cocina2,
            barra2: data.barra2,
            controstock: data.controstock.toString(),
            cargoxconsumo: data.cargoxconsumo.toString(),
            stock_min: data.stock_min,
            grupo_obs: data.grupo_obs
          });
        });
        this.tableData = array;
      })
      /*
                    var array = []
                     allEmpresas().once("value").then((snapshot) => {
                        snapshot.forEach((item) => {
                          console.log(item.val().bd)
                          allMedidas(item.val().bd).once("value").then((snapshot) => {
                              snapshot.forEach((items) => {
                              let data = items.val();                
                                array.push({
                                    nombre: data.nombre,
                                    nombrecorto: data.nombrecorto,
                                    bd: item.val().bd
                                })
                                  this.tableData = array;
                              })
                          })
                        })
                     })*/
    }
  },
}
</script>
