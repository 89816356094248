import store from "@/store/index";
import {
  nuevoflujo,
  sumaContador,
  grabaCabecera,
  grabaDetalle,
  editaProducto,
} from "./db";
import { enviaDocumentoApiSunat } from "./servidorsunat";

export const procesar_items = async (items) => {
  var data = await completa_items(items);
  var array = [
    {
      estado: "PENDIENTE",
      mensajeSunat: "",
      hash: "",
      documentId: "",
      vendedor: store.state.permisos.token,
      total_op_gravadas: data.totaloperaGravada,
      igv: data.totalIGV,
      total_op_gratuitas: data.total_op_gratuitas,
      porcentaje_igv: store.state.configuracion.igv,
      porcentaje_cargo: store.state.configuracion.cargoxconsumo,
      total_op_exoneradas: data.totaloperaExonerada,
      total_cargo: data.total_cargo,
      totalIGV_GRATUITA: data.totalIGV_GRATUITA,
      total: data.total,
    },
    data.item,
  ];
  return array;
};
async function completa_items(arrays) {
  var item = [];
  var totaloperaGravada = 0;
  var totaloperaExonerada = 0;
  var total_op_gratuitas = 0;
  var totalIGV = 0;
  var totalIGV_GRATUITA = 0;
  var total_cargo = 0;
  var total = 0;
  var porcentaje_igv = store.state.configuracion.igv / 100;
  var porcentaje_cargo = store.state.configuracion.cargoxconsumo / 100;
  var a = new Promise((resolve, reject) => {
    arrays.forEach((items, index, array) => {
      var data = items;
      var descuentositem = parseFloat(data.preciodescuento || 0);
      var precio_item = parseFloat(
        redondear(data.precio - descuentositem / data.cantidad)
      );
      console.log(data);
      if (data.operacion != "GRATUITA") {
        total = total + precio_item;
      }
      if (data.cargoxconsumo && porcentaje_cargo != 0) {
        var cargo = parseFloat(
          redondear(precio_item / (parseFloat(porcentaje_cargo) + 1))
        );
        var sumcargo = parseFloat(precio_item - cargo);
        precio_item = cargo;
        total_cargo = total_cargo + parseFloat(sumcargo * data.cantidad);
      }
      if (data.operacion == "GRAVADA") {
        var precioVentaUnitario = precio_item;
        var valor_unitario = precioVentaUnitario / (1 + porcentaje_igv);
        var igv = valor_unitario * data.cantidad * porcentaje_igv;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = valor_unitario * data.cantidad * porcentaje_igv;
        totaloperaGravada = totaloperaGravada + parseFloat(antesimpuesto);
        totaloperaExonerada = totaloperaExonerada + 0;
        totalIGV = totalIGV + parseFloat(totalImpuesto);
      }
      if (data.operacion == "EXONERADA") {
        var precioVentaUnitario = precio_item;
        var valor_unitario = precioVentaUnitario;
        var igv = 0.0;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = 0.0;
        totaloperaGravada = totaloperaGravada + 0;
        totaloperaExonerada = totaloperaExonerada + parseFloat(antesimpuesto);
        totalIGV = totalIGV + parseFloat(totalImpuesto);
      }
      if (data.operacion == "GRATUITA") {
        //console.log("precios", redondear(descuentositem / data.cantidad))
        var precioVentaUnitario = precio_item;
        var valor_unitario = precioVentaUnitario;
        var igv = 0.0;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = 0;
        totaloperaGravada = totaloperaGravada + 0;
        totaloperaExonerada = totaloperaExonerada + 0;
        total_op_gratuitas = total_op_gratuitas + parseFloat(antesimpuesto);
        totalIGV = totalIGV + 0;
        totalIGV_GRATUITA = +totalIGV_GRATUITA + 0;
        valor_unitario = 0.0;
        totalImpuesto = 0.0;
        //antesimpuesto = (precioVentaUnitario*parseFloat(data.cantidad))
      }
      console.log(valor_unitario);
      item.push({
        id: data.id,
        cantidad: data.cantidad,
        nombre: data.nombre,
        medida: data.medida || "UNIDAD",
        cod_medida: obtencodigomedida(
          data.medida || "UNIDAD",
          data.tipoproducto
        ),
        precio: data.precio,
        precioedita: data.precio,
        preciodescuento: data.preciodescuento || 0,
        tipoproducto: data.tipoproducto || "BIEN",
        operacion: data.operacion,
        icbper: false,
        cargoxconsumo: data.cargoxconsumo || false,
        valor_unitario: valor_unitario.toFixed(5),
        valor_total: valorTotal.toFixed(2),
        igv: igv.toFixed(2),
        valor_icbper: 0.0,
        factor_icbper: store.state.configuracion.icbper,
        total_antes_impuestos: antesimpuesto.toFixed(2),
        total_impuestos: totalImpuesto.toFixed(2),
        precioVentaUnitario: redondear(precioVentaUnitario),
      });

      if (index === array.length - 1) {
        var data = {
          item: item,
          totaloperaGravada: totaloperaGravada.toFixed(2),
          totaloperaExonerada: totaloperaExonerada.toFixed(2),
          total_op_gratuitas: total_op_gratuitas.toFixed(2),
          totalIGV: totalIGV.toFixed(2),
          totalIGV_GRATUITA: totalIGV_GRATUITA.toFixed(2),
          total_cargo: total_cargo.toFixed(2),
          total: total.toFixed(2),
        };
        resolve(data);
      }
    });
  });
  a.then((value) => {
    return value;
  });
  return a;
}
export const cobrar_js = async (arrayCabecera, array_item) => {
  await Promise.all([
    grabaCabecera(arrayCabecera.numeracion, arrayCabecera),
    grabaDetalle(arrayCabecera.numeracion, array_item),
    sumarCorrelativo(arrayCabecera),
    flujo_caja(arrayCabecera),
    modifica_stock_array("RESTA", array_item),
  ]);
  if (arrayCabecera.tipocomprobante != "T") {
    enviaDocumentoApiSunat(arrayCabecera, array_item);
  }
  return true;
};
async function flujo_caja(cabecera) {
  for (var i = 0; i < cabecera.modopago.length; i++) {
    var data = cabecera.modopago[i];
    if (cabecera.a_cuenta != 0) {
      if (data.monto != "") {
        var flujo = {
          operacion: "ingreso",
          observacion: "VENTA - " + cabecera.numeracion,
          numeracion_doc: cabecera.numeracion,
          modo: data.nombre,
          fecha: cabecera.fecha,
          total: data.monto,
          estado: "activo",
          responsable: store.state.permisos.correo.slice(0, -13),
          sujeto: store.state.permisos.correo.slice(0, -13),
        };
        await nuevoflujo(flujo);
      }
    }
  }
  return true;
}
async function sumarCorrelativo(data) {
  if (data.tipocomprobante == "T") {
    var valor = (parseInt(data.correlativoDocEmitido) + 1)
      .toString()
      .padStart(8, 0);
    var campo = "ordenticket";
  }
  if (data.tipocomprobante == "B") {
    var valor = (parseInt(data.correlativoDocEmitido) + 1)
      .toString()
      .padStart(8, 0);
    var campo = "ordenboleta";
  }
  if (data.tipocomprobante == "F") {
    var valor = (parseInt(data.correlativoDocEmitido) + 1)
      .toString()
      .padStart(8, 0);
    var campo = "ordenfactura";
  }
  await sumaContador(campo, valor);
  return true;
}
function redondear(valor) {
  return parseFloat(valor).toFixed(store.state.configuracion.decimal);
}
function obtencodigomedida(medida, tipoproducto) {
  if (tipoproducto == "SERVICIO") {
    return "ZZ";
  } else {
    var array = store.state.medidassunat;
    var nomenclatura = "NIU";
    for (var i = 0; i < array.length; i++) {
      if (array[i].nombre == medida) {
        nomenclatura = array[i].corto;
      }
    }
    return nomenclatura;
  }
}

export const modifica_stock_array = async (metodo, arrays) => {
  for (var i = 0; i < arrays.length; i++) {
    var items = arrays[i];
    if (metodo == "SUMA") {
      await suma_stock_app(items);
    }
    if (metodo == "RESTA") {
      await resta_stock_app(items);
    }
  }
  return arrays;
};
export const modifica_stock_unitario = async (metodo, item) => {
  var res = "";
  switch (metodo) {
    case "SUMA":
      res = await suma_stock_app(item);
      break;
    case "RESTA":
      res = await resta_stock_app(item);
      break;
  }
  return res;
};

async function resta_stock_app(data) {
  var producto = store.state.productos.find(
    (id) => String(id.id) === String(data.id)
  );
  if (Boolean(producto)) {
    if (producto.controstock) {
      var nuevo_stock = parseFloat(
        (parseFloat(producto.stock) - parseFloat(data.cantidad)).toFixed(2)
      );
      await editaProducto(data.id, "stock", nuevo_stock);
    }
  }
  return true;
}
async function suma_stock_app(data) {
  var producto = store.state.productos.find(
    (id) => String(id.id) === String(data.id)
  );
  if (Boolean(producto)) {
    if (producto.controstock) {
      var nuevo_stock = parseFloat(
        (parseFloat(producto.stock) + parseFloat(data.cantidad)).toFixed(2)
      );
      await editaProducto(data.id, "stock", nuevo_stock);
    }
  }
  return true;
}
