<template>
    <div grid-list-xs class="pa-6">
        <v-row v-if="true">
            <v-col cols="6">
                <v-select :items="array_periodos" label="PERIODO DE KARDEX" outlined v-model="periodo" dense></v-select>
            </v-col>
            <v-col cols="6">
                <v-text-field dense outlined v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-n12 mb-1">
            <v-col cols="6">
                <v-btn class="" x-small color="info" block @click="filtra()">Filtra</v-btn>
            </v-col>
            <v-col cols="6">
                <v-row class="">
                    <v-col cols="6">
                        S/.{{ suma_pagos().programado }}
                    </v-col>
                    <v-col cols="6">
                        Pagado : S/.{{ suma_pagos().pagado }}
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-simple-table class="elevation-4" fixed-header height="70vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            EMPRESA
                        </th>
                        <th class="text-left">
                            Fecha de Pagos
                        </th>
                        <th class="text-left">
                            Telefono
                        </th>
                        <th class="text-left">
                            Estado
                        </th>
                        <th class="text-left">
                            Plan
                        </th>
                        <th class="text-left">
                            Fecha Pagado
                        </th>
                        <th class="text-left">
                            Pagado
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:75%;">{{ item.bd }} - {{ item.ruc }} - {{ item.name }} - {{
                            extrae(item.usuario) }}</td>
                        <td style="font-size:75%;">{{ conviertefecha(item.fecha_inicio) }}</td>
                        <td style="font-size:75%;">{{ item.telefono }}</td>
                        <td style="font-size:80%;">
                            <v-icon :color="item.color">mdi-circle</v-icon>
                        </td>
                        <td style="font-size:75%;">S/.{{ item.monto_pago }}</td>
                        <td style="font-size:75%;">{{ item.fecha_pago }}</td>
                        <td style="font-size:75%;">S/.{{ item.monto_pagado }}</td>
                        <td style="font-size:75%;">
                            <v-row>
                                <v-col cols="6">
                                    <v-icon color="warning" @click="editar_emp(item)">mdi-cash</v-icon>
                                </v-col>
                                <v-col cols="6">
                                    <v-icon color="green" @click="ver_data(item)">mdi-eye</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dial_datos" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_datos = !dial_datos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h3 class="red--text">{{ periodo_selecto }}</h3>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="" dense>
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="fecha_pago" label="Fecha Pago"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                        <v-text-field outlined type="number" dense v-model="monto_pago"
                            label="Monto Pago"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mt-n6">
                        <v-select outlined dense v-model="modo_pago" :items="array_modos" menu-props="auto" hide-details
                            label="Plan"></v-select>
                    </v-col>
                    <v-col cols="12" class="">
                        <v-textarea outlined dense v-model="nota" auto-grow filled label="Nota" rows="1"></v-textarea>
                    </v-col>
                    <v-card class="pa-2">
                        <croppa :placeholder-font-size="19" canvas-color="transparent" placeholder-color="#000"
                            :width="400" :height="400" :initial-image="imagenURL" v-model="myCroppa">
                        </croppa>
                    </v-card>
                </v-row>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_ver" max-width="750px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_ver = !dial_ver">mdi-close</v-icon>
                    <v-spacer></v-spacer>

                    <v-spacer></v-spacer>

                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table class="elevation-4" fixed-header height="50vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Peridoo
                                </th>
                                <th class="text-left">
                                    Fecha Pago
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Monto
                                </th>

                                <th class="text-left">
                                    Nota
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in item_selecto.pagos" :key="item.id">
                                <td style="font-size:75%;">{{ item.periodo }}</td>
                                <td style="font-size:75%;">{{ conviertefecha(item.fecha_pago) }}</td>
                                <td style="font-size:75%;">{{ item.modo_pago }}</td>
                                <td style="font-size:75%;">S/.{{ item.monto_pago }}</td>
                                <td style="font-size:75%;">{{ item.nota }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>


            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    allempresas_pagos,
    nuevo_pago,
    guardapago,
    nueva_lista_pago
} from '../../db'
import firebase from 'firebase'
import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
export default {
    data: () => ({
        buscar: '',
        array_periodos: ['2023-06', '2023-07', '2023-08', '2023-09', '2023-10', '2023-11', '2023-12'],
        array_modos: ['YAPE', 'PLIN', 'INTERBANK', 'BCP', 'BBVA', 'SCOTIA'],
        periodo: moment(String(new Date)).format('YYYY-MM'),
        desserts: [],
        dial_datos: false,
        dial_ver: false,
        item_selecto: [],
        fecha_pago: moment(String(new Date)).format('YYYY-MM-DD'),
        monto_pago: '',
        modo_pago: 'YAPE',
        nota: '',
        periodo_selecto: '',
        archivo: '',
        myCroppa: {},
        value: '',
        progress: false,
        selectedFile: null,
        Uploadvalue: 0,
        imagenURL: ''
    }),

    created() {
        this.filtra()
    },

    computed: {
        listafiltrada() {
            return this.desserts.filter((item) => (item.bd + item.ruc + item.name + item.usuario)
                .toLowerCase().includes(this.buscar.toLowerCase()))
        }

    },

    methods: {
        ver_data(item) {
            this.item_selecto = item
            this.dial_ver = true
        },
        async save() {
            if (this.monto_pago == 0 || this.monto_pagado == '') {
                store.commit('dial_alert', 'no puede ser 0')
                return
            }
            store.commit("dialogoprogress")

            await this.myCroppa.generateBlob(async (blob) => {
                if (blob == null) {
                    var task = ''
                } else {
                    var task = await guardapago(blob, 'pagos/' + this.periodo + '/' + this.item_selecto.ruc).getDownloadURL().then(url => url)
                }
                if (this.item_selecto.pagos != undefined) {
                    var array = this.item_selecto.pagos
                } else {
                    var array = []
                }
                array.push({
                    url: task,
                    modo_pago: this.modo_pago,
                    monto_pago: this.monto_pago,
                    periodo: this.periodo,
                    nota: this.nota,
                    fecha_pago: moment(String(this.fecha_pago)) / 1000,
                    fecha_ingreso: moment().unix()
                })
                await nuevo_pago(this.item_selecto.id + '/pagos', array)
                this.dial_datos = false
                this.filtra()
                this.selectedFile = null
                this.imagenURL = ''
                store.commit("dialogoprogress")

                return task
            }, 'image/webp', 0.8)

        },
        async filtra() {
            this.periodo_selecto = this.periodo
            store.commit("dialogoprogress")
            var snap = await allempresas_pagos().once('value')
            let array = [];
            snap.forEach((item) => {
                let key = item.key;
                let data = item.val();
                data.key = key
                if (this.analiza_fecha(data.fecha_inicio)) {
                    data.color = 'error'
                } else {
                    data.color = 'warning'
                }

                if (data.pagos != undefined) {
                    var a = data.pagos.find(item => item.periodo == this.periodo)
                    if (a != undefined) {
                        data.monto_pagado = a.monto_pago
                        data.fecha_pago = this.conviertefecha(a.fecha_pago)
                        data.color = 'success'
                    } else {
                        data.monto_pagado = 0
                    }
                } else {
                    data.monto_pagado = 0
                }
                array.push(data);
            });
            this.desserts = array;
            store.commit("dialogoprogress")
        },
        analiza_fecha(fecha) {
            var periodo_selecto = moment(String(this.periodo), 'YYYY-MM')
            var periodo_actual = moment(String(new Date))

            if (periodo_selecto.isSame(periodo_actual, 'M')) {
                var hoy = moment(String(new Date))
                var data = moment.unix(fecha)
                var result = false
                if (data.isBefore(hoy, 'days')) {
                    result = true
                }
                if (data.isSame(hoy, 'days')) {
                    result = true
                }
                return result
            } else {
                if (periodo_selecto.isBefore(periodo_actual, 'M')) {
                    var result = true
                    return result
                }
            }

        },
        editar_emp(data) {
            this.item_selecto = data
            this.dial_datos = true
        },
        suma_pagos() {
            var suma = 0
            var programa = 0
            for (var i = 0; i < this.listafiltrada.length; i++) {
                var data = this.listafiltrada[i]
                suma = suma + parseFloat(data.monto_pagado)
                programa = programa + parseFloat(data.monto_pago)
            }
            var arra = {
                pagado: suma,
                programado: programa
            }
            return arra
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        extrae(data) {
            return data.substring(0, data.indexOf('@') + 1)
        },

    },
}
</script>
