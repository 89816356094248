<template>
    <div class="pa-3">
        <v-row class="mx-auto text-center">
            <v-col cols="3">
                <v-btn block color="info" elevation="3" small @click="dialogoFiltro = !dialogoFiltro">Nuevo
                    Costeo</v-btn>
                <v-btn class="mt-2" block color="error" elevation="3" small @click="ir_recetas()">Recetas</v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn color="success" small @click="exportar()">
                    Exportar
                    <v-icon class="ml-1" small>
                        mdi-microsoft-excel
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date1" label="Inicio"></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="mt-2" elevation="6">
            <v-simple-table fixed-header height="70vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha Costeo
                            </th>
                            <th class="text-left">
                                Observacion
                            </th>
                            <th class="text-left">
                                Inicio => Fin
                            </th>
                            <th class="text-left">
                                Costo Total
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.fecha_creacion">
                            <td>{{ conviertefecha(item.fecha_creacion) }}</td>
                            <td>{{ item.observacion }}</td>
                            <td>{{ conviertefecha(item.fecha_inicio) }} -> {{ conviertefecha(item.fecha_fin) }}</td>
                            <td>S/.{{ item.costo_total.toFixed(2) }}</td>
                            <td> <v-icon color="green" @click.prevent="ver_detalle(item)">mdi-eye</v-icon></td>
                        </tr>
                    </tbody>
                </template>

            </v-simple-table>
        </v-card>

        <v-dialog v-model="dialogoFiltro" max-width="550px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoFiltro = !dialogoFiltro">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <h3 class="text-center">Seleccione Rango de Venta</h3>
                <v-row class="text-center mt-n1 mb-n6">
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="date3" label="INICIO"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="date4" label="FIN"></v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="procesa_info()" text>Genera</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_costeo" max-width="650px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_costeo = !dial_costeo">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <h4 class="text-center">Insumos Usados de la fecha
                    <strong class="red--text">{{ date3 }}</strong>
                    a la
                    <strong class="red--text">{{ date4 }}</strong></strong>
                </h4>
                <v-card class="pa-1" elevation="2">
                    <v-simple-table fixed-header height='50vh' dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Insumo
                                    </th>
                                    <th class="text-left">
                                        Costo x Und
                                    </th>
                                    <th class="text-left">
                                        Cantidad Usada
                                    </th>
                                    <th class="text-left">
                                        Costo Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in costeo_insumos" :key="item.id">
                                    <td>{{ item.nombre }}</td>
                                    <td>S/.{{ item.costo }}</td>
                                    <td>{{ item.cantidad }} - {{ item.medida }}</td>
                                    <td>S/.{{ item.costo_total }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <v-textarea class="mt-2 mb-n4" dense outlined v-model="observacion" auto-grow filled label="Observacion"
                    rows="1"></v-textarea>
                <v-btn v-if="edit" color="success" small @click="graba_costeo()" block>Grabar</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import * as XLSX from "xlsx";
import {
    allcosteo_receta,
    nuevo_costeo_receta,
    allCabecera,
    consultaDetalle,
    allInsumos,
    allReceta,
    sumaContador,
    editaInsumos,
    nuevoMovimiento,
    obtenContador,
} from '../../db'
import store from '@/store/index'
export default {
    data: () => ({
        dialogoFiltro: false,
        dial_costeo: false,
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        date3: moment(String(new Date)).format('YYYY-MM-DD'),
        date4: moment(String(new Date)).format('YYYY-MM-DD'),
        lista_costeos: [],
        desserts: [],
        recetas: [],
        catalogo_insumos: [],
        costeo_insumos: [],
        consolida_t: false,
        observacion: '',
        costo_total: 0,
        edit: false,
    }),
    created() {
        this.date1 = moment(String(new Date)).subtract(5, 'd').format('YYYY-MM-DD')
        this.inicio()
    },
    mounted() {
        allcosteo_receta().orderByChild('fecha_creacion').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    beforeDestroy() {
        allcosteo_receta().orderByChild('fecha_creacion').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            allcosteo_receta().orderByChild('fecha_creacion').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            return this.lista_costeos
        },
    },
    methods: {
        exportar() {
            const consolidado = {};

            this.lista_costeos.forEach(coste => {
                coste.data_insumos.forEach(insumo => {
                    const id = insumo.id;
                    const nombre = insumo.nombre;
                    const medida = insumo.medida;

                    // Convertir cantidad a número por si viene como string
                    const cantidad = parseFloat(insumo.cantidad) || 0;

                    if (!consolidado[id]) {
                        consolidado[id] = {
                            id,
                            nombre,
                            medida,
                            cantidad: 0
                        };
                    }

                    consolidado[id].cantidad += cantidad;
                });
            });

            // Convertir el objeto a array
            const listaFinal = Object.values(consolidado);

            console.log("✅ Insumos consolidados:", listaFinal);

            // Opcional: exportar a Excel si deseas
            // 👉 Exportar a Excel
            const ws = XLSX.utils.json_to_sheet(listaFinal);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Insumos Consolidados");

            const nombreArchivo = "Consolidado_Insumos.xlsx";
            XLSX.writeFile(wb, nombreArchivo);
        },
        ver_detalle(data) {
            this.edit = false
            this.costeo_insumos = data.data_insumos
            this.observacion = data.observacion
            this.date3 = moment.unix(data.fecha_inicio).format('YYYY-MM-DD')
            this.date4 = moment.unix(data.fecha_fin).format('YYYY-MM-DD')
            this.dial_costeo = true
        },
        async graba_insumos(val) {
            for (var i = 0; i < val.length; i++) {
                var data = val[i]
                var insumo = this.catalogo_insumos.find(id => String(id.id) === String(data.id))
                var total = parseFloat(insumo.stock) - parseFloat(data.cantidad)
                await editaInsumos(data.id, 'stock', total.toFixed(2))
            }
        },
        async graba_costeo() {
            if (confirm('Seguro de Grabar este costeo?')) {
                store.commit("dialogoprogress")
                await this.graba_insumos(this.costeo_insumos)
                var array = {
                    fecha_creacion: moment().unix(),
                    fecha_inicio: moment(String(this.date1)) / 1000,
                    fecha_fin: moment(String(this.date2)) / 1000,
                    data_insumos: this.costeo_insumos,
                    observacion: this.observacion,
                    costo_total: this.suma_total(this.costeo_insumos)
                }
                await nuevo_costeo_receta(array)
                this.dial_costeo = false
                store.commit("dialogoprogress")
            }
        },
        suma_total(val) {
            console.log(val)
            var suma = 0
            for (var i = 0; i < val.length; i++) {
                var data = val[i]
                suma = suma + parseFloat(data.costo_total)
            }
            return suma
        },
        inicio() {
            allReceta().once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.recetas.push(item.val())
                })
            })
            allInsumos().once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.catalogo_insumos.push(item.val())
                })
            })
        },
        consolida_todo() {
            for (var i = 0; i < this.desserts.length; i++) {
                this.desserts[i].consolida = this.consolida_t
            }
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data)
            });
            this.lista_costeos = array
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },

        async procesa_info() {
            var array = []
            var snapshot = await allCabecera()
                .orderByChild('fecha')
                .startAt(moment(String(this.date3)) / 1000)
                .endAt(moment(String(this.date4)).add(23, 'h').add(59, 'm').add(59, 's') / 1000)
                .once("value")
            if (snapshot.exists()) {
                snapshot.forEach((item) => {
                    var data = item.val()
                    array.push(data)
                })

            } else {
                store.commit('dial_alert', 'Sin data')
                return
            }
            var resp = await this.procesa_comprobantes(array)
            var costeo_result = await this.procesa_insumo(resp)
            this.costeo_insumos = costeo_result
            this.dialogoFiltro = false
            this.edit = true
            this.dial_costeo = true
        },
        procesa_insumo(arrays) {

            var array_detalle = []
            var a = new Promise((resolve, reject) => {
                arrays.forEach((items, index, array) => {
                    var data = items
                    var receta = this.recetas.find(id => String(id.idproducto) === String(data.id))

                    if (receta != undefined) {
                        var array_insumos = receta.arrayinsumos
                        for (var i = 0; i < array_insumos.length; i++) {
                            var insumo = array_insumos[i]

                            insumo.id = insumo.idinsumo
                            console.log(receta)
                            console.log(insumo.idinsumo)
                            var catalogo_insumo = this.catalogo_insumos.find(id => String(id.id) === String(insumo.idinsumo))
                            if (catalogo_insumo == undefined) {
                                store.commit('dial_alert', 'ERROR EN LA RECETA ' + receta.nombreproducto)
                                return
                            }
                            if (this.buscarArray(array_detalle, insumo) == '') {
                                var cantidad = (parseFloat(insumo.cantidadinsumo) * parseFloat(data.cantidad))
                                array_detalle.push({
                                    id: insumo.idinsumo,
                                    nombre: insumo.nombreinsumo,
                                    medida: insumo.medida,
                                    costo: catalogo_insumo.costo,
                                    cantidad: (cantidad).toFixed(3),
                                    costo_total: (parseFloat(catalogo_insumo.costo) * parseFloat(cantidad)).toFixed(2),
                                })
                                console.log(array_detalle)
                            } else {
                                var catalogo_insumo = this.catalogo_insumos.find(id => String(id.id) === String(insumo.idinsumo))
                                var cantidad = (parseFloat(insumo.cantidadinsumo) * parseFloat(data.cantidad)).toFixed(3)
                                var pos = this.buscarposicion(array_detalle, insumo)
                                var array = this.buscarArray(array_detalle, insumo)

                                var suma_cantidad = parseFloat((parseFloat(insumo.cantidadinsumo) * parseFloat(data.cantidad)).toFixed(3)) + parseFloat(parseFloat(array.cantidad).toFixed(3))
                                console.log(insumo.nombreinsumo, suma_cantidad)
                                array_detalle[pos].cantidad = suma_cantidad
                                var suma_total = parseFloat(catalogo_insumo.costo) * parseFloat(cantidad) + parseFloat(array.costo_total)
                                array_detalle[pos].costo_total = suma_total
                            }
                        }
                    }
                    //console.log(index, array.length)
                    if (index === arrays.length - 1) {

                        resolve(array_detalle)
                    };

                })
            })
            return a
        },
        procesa_comprobantes(arrays) {
            var array_detalle = []
            var a = new Promise((resolve, reject) => {
                arrays.forEach((items, index, array) => {
                    var data = items
                    consultaDetalle(data.numeracion).once("value").then((snapshot) => {
                        snapshot.forEach((item) => {
                            var producto = item.val()
                            var catalogo = store.state.productos.find(id => String(id.id) === String(producto.id))
                            if (this.buscarArray(array_detalle, producto) == '') {
                                array_detalle.push({
                                    id: producto.id,
                                    nombre: producto.nombre,
                                    medida: producto.medida,
                                    cantidad: parseFloat(producto.cantidad),
                                })
                            } else {

                                var pos = this.buscarposicion(array_detalle, producto)
                                var array = this.buscarArray(array_detalle, producto)

                                var suma_cantidad = parseFloat(producto.cantidad) + parseFloat(array.cantidad)
                                //   console.log(producto.nombre, suma_cantidad)
                                array_detalle[pos].cantidad = suma_cantidad
                            }
                        })
                        if (index === arrays.length - 1) {
                            resolve(array_detalle)
                        };
                    })
                })
            })
            return a
        },
        buscarArray(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id.id) {
                    a = array[i]
                }
            }
            return a
        },
        buscarposicion(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id.id) {
                    a = i
                }
            }
            return a
        },
        ir_recetas() {
            this.$router.push({
                name: 'recetas'
            })
        }
    }
}
</script>
