<template>
    <div class="pa-3">
        <v-row>
            <v-col cols="12" md="4"
                v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && $vuetify.breakpoint.width > $vuetify.breakpoint.height)">
                <cat_fijo ref="catFijo" @agrega_lista="agregar_lista($event)"></cat_fijo>
            </v-col>
            <v-col cols="12" md="8">
                <v-card class="mt-1">

                    <v-card-text>
                        <v-row class="mt-n4" dense>
                            <v-col cols="6" xs="6">
                                <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                            </v-col>
                            <v-col cols="6" xs="6">
                                <v-text-field type="number" outlined dense rounded id="cod" v-model="codigo"
                                    label="Codigo" append-icon="mdi-barcode-scan" @click:append="buscaProducto()"
                                    @keyup.enter="buscaProducto()"
                                    :autofocus="$store.state.configuracion.codbarras"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-row class="mx-auto mt-n8" dense>
                        <v-col cols="4" class="text-center">
                            <v-btn small elevation="3" rounded color="success" @click="dial_catalogo = !dial_catalogo">
                                Catalogo
                                <v-icon color="white" class="mx-auto text--center" small>mdi-archive-arrow-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <v-btn small elevation="6" rounded color="info" @click="dialogAgrega = !dialogAgrega">
                                Agregar
                                <v-icon color="white" class="mx-auto text--center" small>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="text-center" v-if="$store.state.baseDatos.restaurante == true">
                            <v-btn small elevation="6" rounded color="warning" @click="dial_dividir = !dial_dividir">
                                Dividir <v-icon color="white" class="mx-auto text--center"
                                    small>mdi-content-cut</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                <h4 class="mb-n5 mt-1 red--text" v-if="id_comanda != ''">
                    <div v-if="!es_delivery"> Cuenta Mesa : N°{{ id_comanda }}</div>
                    <div v-if="es_delivery"> Cuenta Delivery : {{ nom_delivery }}</div>
                </h4>
                <v-card class="mt-5">
                    <v-simple-table dark fixed-header dense height="50vh">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Descripcion
                                    </th>
                                    <th class="text-left">
                                        Precio
                                    </th>
                                    <th v-if="sumaDescuentos() != 0" class="text-left">
                                        Desc.
                                    </th>
                                    <th class="text-left">
                                        Total
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="item in listaproductos" :key="item.id" @click.prevent="editaProducto(item)">
                                    <td> <strong class="red--text"> {{ item.cantidad }} x</strong> {{ item.nombre }}
                                        <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                            Gratuita</strong>
                                    </td>
                                    <td v-if="item.operacion == 'GRATUITA'">S/. 0.0</td>
                                    <td v-if="item.operacion != 'GRATUITA'">S/.{{ item.precio }}</td>
                                    <td class="red--text" v-if="sumaDescuentos() != 0">S/.{{
                                        redondear(item.preciodescuento)
                                    }}
                                    </td>
                                    <td v-if="item.operacion == 'GRATUITA'">S/. 0.0</td>
                                    <td v-if="item.operacion != 'GRATUITA'">S/.{{ redondear((item.precio *
                                        item.cantidad) - item.preciodescuento) }}</td>
                                </tr>
                            </tbody>
                            <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true"
                                class="mt-8">
                                <v-icon color="red" @click="buscaComandas('mesa')" class="mx-auto text--center"
                                    large>mdi-table-chair</v-icon>
                            </v-row>
                            <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true"
                                class="mt-8">
                                <v-icon color="red" @click="buscaComandas('delivery')" class="mx-auto text--center"
                                    large>mdi-moped-electric-outline</v-icon>
                            </v-row>
                        </template>

                    </v-simple-table>

                </v-card>

                <v-card class="mt-3 pa-2">
                    <v-row>
                        <v-col cols="7">
                            <v-list-item-content class="ml-3">
                                <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                                    <h3>SubTotal: S/.{{ sumaTotal() }}</h3>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                                    <h4 class="red--text">Descuentos: S/.{{ sumaDescuentos() }}</h4>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <h2>Total: S/.{{ redondear(sumaTotal() - sumaDescuentos()) }}</h2>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-col>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="3">
                            <v-btn block elevation="15" rounded v-if="listaproductos != ''" color="error"
                                @click="cobrar()">
                                Cobrar <span v-if="!$store.state.esmovil">(F1)</span>
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogoProducto" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dialogoProducto = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-checkbox v-model="es_tg" label="Es Gratuita"></v-checkbox>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense v-if="$store.state.permisos.caja_edita_cantidad">

                    <v-col cols="4" xs="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>

                    <v-col cols="4" xs="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>

                </v-row>
                <v-row class="mx-auto text-center mt-n2" dense>
                    <v-col cols="12">
                        <v-textarea dense class="mt-n2" outlined v-model="nombreEdita" auto-grow filled
                            color="deep-purple" label="Descripcion" rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mx-auto text-center mt-n3" dense>
                    <v-col @focus="$event.target.select()" cols="6" v-if="$store.state.permisos.caja_edita_precio">
                        <v-text-field outlined dense @keyup.enter="grabaEdita()" type="number" v-model="precioedita"
                            label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field @focus="$event.target.select()" outlined dense @keyup.enter="grabaEdita()"
                            type="number" v-model="preciodescuento" label="Descuento"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mx-auto mt-n3 text-center" dense>
                    <v-col cols="6" xs="6">
                        <v-text-field @focus="$event.target.select()" outlined dense @keyup.enter="grabaEdita()"
                            type="number" v-model="porcentaje" append-icon="%" label="Descuento %"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <h4 class="pa-3">S/.{{ conversion }}</h4>
                    </v-col>
                </v-row>

                <v-card-actions class="mt-n6">

                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_comandas" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_comandas = !dial_comandas">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-simple-table height="60vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Numero Mesa
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_comandas" :key="item.id">
                                <td>{{ item.nombre }}</td>
                                <td>
                                    <v-icon @click="copia_comanda(item)" color="red">mdi-magnify</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
        <cobrar v-if="dial_cobro" @cierre="dial_cobro = false" @terminar='termina_venta($event)'
            :cabecera="cabecera_final" :items="items_final" :cliente="arra_cliente" />
        <cateogrias v-if="dial_catalogo" @cierra="dial_catalogo = false" @array="agregar_lista($event)" />
        <agrega_producto v-if="dialogAgrega" @cierra="dialogAgrega = false"
            @agrega_lista="agregar_lista($event), dialogAgrega = false" />
        <imprime v-if="genera_pdf" :data="cabecera_cobro" @cierra="genera_pdf = false, captura_foco()" />
        <dividir v-if="dial_dividir" @cierra="dial_dividir = false" @termina="termina_divide($event)" />

    </div>
</template>

<script>
import {
    allComandas,
    buscaComanda,
    alldelivery,
    buscaComandaDelivery,
    eliminaDelivery,
    eliminaComandaDelivery,
    editaaMesa,
    nuevaComanda,
    busca_mesa
} from '../../db'
import moment from 'moment'
import {
    procesar_items
} from '../../funciones_generales'
import store from '@/store/index'
import cateogrias from '@/components/dialogos/dialogocatalogo'
import cobrar from '@/views/ventas/cobro_final'
import agrega_producto from '@/views/ventas/agrega_producto'
import dividir from '@/views/ventas/separacion_cuenta'
import imprime from '@/components/dialogos/dialog_imprime'
import cat_fijo from '@/components/catalogo_fijo'
export default {
    name: 'caja',

    components: {
        cateogrias,
        cobrar,
        agrega_producto,
        imprime,
        dividir,
        cat_fijo
    },

    data() {
        return {
            dial_catalogo: false,
            dial_dividir: false,
            genera_pdf: false,
            dial_cobro: false,
            dialogAgrega: false,
            dial_comandas: false,
            dialogoProducto: false,
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            codigo: '',
            lista_comandas: [],
            listaproductos: [],
            totalDescuento: '',
            totalDocumento: '',
            cantidadEdita: '',
            preciodescuento: '',
            precioedita: '',
            nombreEdita: '',
            porcentaje: 0,
            item_selecto: [],
            cabecera_final: [],
            items_final: [],
            id_comanda: '',
            es_delivery: false,
            comanda_nueva: [],
            es_division: false,
            nom_delivery: '',
            buscar: '',
            activaproductos: false,
            dialo_cantidad: false,
            cantidad: '',
            producto_selecto: '',
            categoriaselecta: '',
            es_tg: false,
            arra_cliente: [],
        }
    },
    created() {

    },

    mounted() {
        window.addEventListener("keydown", this.detectarTecla);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.detectarTecla);
    },
    computed: {
        conversion() {
            var total = this.precioedita * (this.porcentaje / 100)
            return total.toFixed(2)
        },
        listafiltrada() {
            var invent = store.state.productos
            return invent.filter((item) =>
                (item.activo) == true)
                .filter((item) => (item.categoria)
                    .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                .filter((item) => (item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        }
    },
    methods: {
        captura_foco() {
            this.$nextTick(() => {
                if (this.$refs.catFijo) {
                    this.$refs.catFijo.enfocarInput(); // Llama al método del hijo
                }
            });
        },
        detectarTecla(event) {
            if (event.key === "F1" && !store.state.esmovil) {
                event.preventDefault(); // Evita que el navegador abra la ayuda
                this.cobrar();
            }
        },

        comparafecha() {
            const hoy = moment().subtract(3, 'days').format('YYYY-MM-DD');
            const fecha = moment(this.date).format('YYYY-MM-DD');
            return moment(fecha).isAfter(hoy);
        },
        verdate() {
            var fecha = moment(String(this.date)) / 1000
            if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                fecha = moment().unix()
            }
            return fecha
        },
        async cobrar() {
            if (!this.comparafecha()) {
                store.commit('dial_alert', 'Fecha Excede el limite')
                return
            }
            var fechahoy = this.verdate()
            var totalcuenta = this.sumaTotal()
            var totaldescuentos = this.sumaDescuentos()
            var resp = await procesar_items(this.listaproductos)
            console.log(resp)
            var cabecera = resp[0]
            var items = resp[1]
            cabecera.fecha = fechahoy
            cabecera.vencimientoDoc = fechahoy
            cabecera.total = totalcuenta
            cabecera.descuentos = totaldescuentos
            if (this.id_comanda != '') {
                if (this.es_delivery) {
                    cabecera.es_delivery = true
                    cabecera.id_comanda = this.nom_delivery
                } else {
                    cabecera.es_delivery = false
                    cabecera.id_comanda = this.id_comanda
                }

            }
            this.cabecera_final = cabecera
            this.items_final = items
            this.dial_cobro = true
        },
        async termina_venta(val) {
            this.cabecera_cobro = val
            if (this.id_comanda != '') {
                if (this.es_division) {
                    console.log(this.comanda_nueva)
                    await nuevaComanda(this.id_comanda, this.comanda_nueva)

                } else {
                    if (this.es_delivery) {
                        await Promise.all([
                            eliminaDelivery(this.id_comanda),
                            eliminaComandaDelivery(this.id_comanda)
                        ])
                    } else {
                        await Promise.all([
                            editaaMesa(this.id_comanda + '/estado', "PENDIENTE"),
                            editaaMesa(this.id_comanda + '/cliente', ""),
                            editaaMesa(this.id_comanda + '/ref', ""),
                            nuevaComanda(this.id_comanda, [])
                        ])
                    }
                }

            }
            this.genera_pdf = true
            store.commit("dialogoprogress")
            this.dial_cobro = false
            this.listaproductos = []
            this.cabecera_final = []
            this.item_final = []
            this.es_division = false
            this.id_comanda = ''
            this.es_delivery = false
        },
        termina_divide(val) {
            var array = val[0]
            if (val[1] == '') {
                this.es_division = false
            } else {
                this.es_division = true
            }
            this.comanda_nueva = val[1]
            this.id_comanda = val[2]
            this.listaproductos = []
            array.forEach((item) => {
                var data = item
                data.cantidad = item.cantidadedita
                this.agregar_lista(data)
            })
            this.dial_dividir = false
        },
        agregar_lista(value) {
            if (!Boolean(value.uuid)) {
                value.uuid = value.id
            }
            this.listaproductos.push({
                uuid: this.create_UUID().substring(29),
                id: value.id,
                cantidad: value.cantidad,
                nombre: value.nombre,
                medida: value.medida,
                precio: value.precio,
                preciodescuento: 0,
                costo: value.costo,
                tipoproducto: value.tipoproducto,
                operacion: value.operacion,
                icbper: false,
                controstock: value.controstock,
                cargoxconsumo: value.cargoxconsumo
            })
        },
        sumaproductos(item) {
            var pos = this.listaproductos.map(e => e.id).indexOf(item)
            this.listaproductos[pos].cantidad++
        },
        buscaProducto() {
            if (this.codigo == '') {
                return
            }
            if (store.state.configuracion.codbarras) {
                var array = store.state.productos.find(item => String(item.codbarra) == String(this.codigo.trim()))
            } else {
                var array = store.state.productos.find(item => String(item.id) == String(this.codigo.trim()))
            }
            this.codigo = array.id
            if (this.listaproductos.find(item => item.id == this.codigo)) {
                this.sumaproductos(this.codigo)
            } else {
                var producto = store.state.productos.find(item => item.id == this.codigo)
                if (Boolean(producto)) {
                    producto.cantidad = 1
                    this.agregar_lista(producto)
                } else {
                    store.commit('dial_alert', 'Producto no Existe')
                }
            }
            this.codigo = ''
        },
        grabaEdita() {
            if (this.cantidadEdita == '' || this.cantidadEdita == 0) {
                store.commit('dial_alert', 'ingrese cantidad valida')
                return
            }
            if (this.precioedita == 0) {
                store.commit('dial_alert', "Precio no puede ser 0")

                return
            }
            if (this.precioedita <= this.preciodescuento) {
                store.commit('dial_alert', "Precio no puede ser menor/igual a 0")
                return
            }
            if (this.item_selecto.controstock) {
                var producto = store.state.productos.find(item => item.id == this.item_selecto.id)
                if (producto.stock < this.cantidadEdita) {
                    store.commit('dial_alert', 'Producto sin Stock')
                    return
                }
            }

            if (this.porcentaje != '' && this.porcentaje != 0) {
                this.preciodescuento = this.conversion
            }
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos[pos].nombre = this.nombreEdita
            this.listaproductos[pos].cantidad = this.cantidadEdita
            this.listaproductos[pos].precio = this.precioedita
            this.listaproductos[pos].preciodescuento = this.preciodescuento
            if (this.es_tg) {
                this.listaproductos[pos].operacion = 'GRATUITA'
            }
            this.dialogoProducto = false
        },
        suma() {
            if (this.item_selecto.controstock) {
                var producto = store.state.productos.find(item => item.id == this.item_selecto.id)
                if (producto.stock <= this.cantidadEdita) {
                    store.commit('dial_alert', 'Producto sin Stock')
                    return
                }
            }
            this.cantidadEdita = parseInt(this.cantidadEdita) + 1
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        eliminaedita() {
            if (this.id_comanda != '') {
                this.id_comanda = ''
                this.es_delivery = false
                this.listaproductos = []
            }
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos.splice(pos, 1)
            this.dialogoProducto = false
        },
        editaProducto(val) {
            this.es_tg = false
            if (val.operacion == 'GRATUITA') {
                this.es_tg = true
            }
            this.item_selecto = val
            this.cantidadEdita = val.cantidad
            this.nombreEdita = val.nombre
            this.precioedita = val.precio
            this.preciodescuento = val.preciodescuento
            this.dialogoProducto = true
        },
        async buscaComandas(modo) {
            try {
                store.commit("dialogoprogress");
                this.lista_comandas = [];
                this.es_delivery = modo !== 'mesa';

                let snapshot;
                if (modo === 'mesa') {
                    snapshot = await allComandas().once('value');
                } else {
                    snapshot = await alldelivery().once('value');
                }

                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        const key = item.key;
                        const nombre = modo === 'mesa' ? key : item.val().cliente;
                        this.lista_comandas.push({
                            id: key,
                            nombre
                        });
                    });
                } else {
                    store.commit('dial_alert', modo === 'mesa' ? 'Mesas Vacías' : 'Sin Deliveris');
                    return;
                }
                this.dial_comandas = true;
            } catch (error) {
                console.error('Error al buscar comandas:', error);
                store.commit('dial_alert', 'Hubo un error al buscar comandas.');
            } finally {
                store.commit("dialogoprogress");
            }
        },
        async copia_comanda(val) {

            try {
                store.commit("dialogoprogress");
                console.log(val)
                let snap;
                if (this.es_delivery) {
                    snap = await buscaComandaDelivery(val.id).once('value');
                } else {
                    snap = await buscaComanda(val.id).once('value');
                    var snaps = await busca_mesa(val.id).once('value');
                    if (snaps.exists()) {
                        this.arra_cliente = snaps.val().cliente
                    }
                }

                if (snap.exists()) {
                    this.id_comanda = val.id;
                    this.nom_delivery = val.nombre;

                    snap.forEach((item) => {
                        let data = item.val();
                        const producto = store.state.productos.find(p => p.id === data.id);

                        if (!producto) {
                            data.operacion = data.operacion || store.state.configuracion.operacion;
                            data.tipoproducto = 'BIEN';
                            data.cargoxconsumo = false;
                            data.controstock = false;
                            data.costo = 0;
                            data.medida = 'UNIDAD';
                        } else {
                            data.operacion = data.operacion || store.state.configuracion.operacion;
                            data.tipoproducto = producto.tipoproducto;
                            data.cargoxconsumo = producto.cargoxconsumo;
                            data.controstock = producto.controstock;
                            data.costo = producto.costo;
                            data.medida = producto.medida;
                        }

                        this.agregar_lista(data);
                    });
                } else {
                    store.commit('dial_alert', 'Error: Comanda no encontrada');
                    return;
                }

                this.dial_comandas = false;
            } catch (error) {
                console.error('Error al copiar comanda:', error);
                store.commit('dial_alert', 'Hubo un error al copiar la comanda.');
            } finally {

                store.commit("dialogoprogress");
            }
        },
        sumaTotal() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].operacion != 'GRATUITA') {
                    suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precio)
                }
            }
            this.totalDocumento = suma.toFixed(2)
            return suma.toFixed(2)
        },
        sumaDescuentos() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + parseFloat(this.listaproductos[i].preciodescuento)
            }
            this.totalDescuento = (suma).toFixed(2)
            return (suma).toFixed(2)
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
    },

}
</script>
<style scoped>
/* Estilos adicionales si es necesario */
.v-card-text {
    -webkit-line-clamp: 2;
    /* Número máximo de líneas */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hoverable:hover {
    /* Estilo de hover para el v-card */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
