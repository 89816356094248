<template>
  <div class="pa-3">
    <div class="text-center mx-auto mb-n10" v-if="false">
      <v-row>
        <v-col class="grow" cols="1">
          <v-icon @click="dialog = true" large color="green">mdi-music-note</v-icon>
        </v-col>
        <v-col class="grow" cols="1">
          <v-icon @click="ira()" large color="blue">mdi-chart-bar</v-icon>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="grow">
          <v-icon @click="dialogElimina = !dialogElimina" large color="yellow">mdi-flash</v-icon>
        </v-col>
      </v-row>

    </div>

    <div class="text-center mx-auto mt-12" v-if="desserts == ''">
      <v-avatar height="200" width="200">
        <v-img class="text-center mx-auto " height="200" width="200" src="barra.png">
        </v-img>
      </v-avatar>
      <h2>TUS PEDIDOS LLEGARÁN AQUI!</h2>
    </div>

    <v-row class="mx-auto mt-n2" style="text-align:center" dense>
      <v-col v-for="item in desserts" :key="item.id" cols="6" md="3" sm="4" xs="4">

        <v-card elevation="3">
          <v-progress-linear v-if="true" buffer-value="0" :value="tempo2(item.fecha)" :color="tempo3(item.fecha)"
            height="5" stream class="mb-1">
          </v-progress-linear>
          <v-row no-gutters class="mb-n3">
            <v-col cols="6">
              <v-btn x-small @click="completaPedido(item)" color="white">
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn x-small @click="cancelaPedido(item)" color="white">
                <v-icon color="red">mdi-window-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text class="red--text" style="font-size:1.95vh">
            <v-row dense>
              <v-col cols="7">
                <span class="black--text" v-if="item.delivery">Deliv. : <strong>{{ item.idmesa }}</strong> </span>
                <span class="black--text" v-else>MESA : <strong>{{ item.idmesa }}</strong> </span>
                <p class="black--text mb-n1" v-if="!item.delivery">Resp. : <strong>{{ item.usuario }}</strong> </p>
                <p class="mb-n1" v-if="item.obsmesa != '-'">{{ item.obsmesa }}</p>
              </v-col>
              <v-col cols="5">
                <span>{{ tempo(item.fecha) }} </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="red--text mt-n2" style="font-size:2vh;text-align:left ">
            <div v-for="items in item.data" :key="items.uuid" cols="12" class="mt-n3">
              <v-row no-gutters>
                <v-col cols="2">
                  <p class="black--text">{{ items.cantidad }}</p>
                </v-col>
                <v-col cols="10">
                  <p class="black--text">{{ items.nombre }}</p>
                </v-col>
              </v-row>

              <p class="mt-n4" style="font-size:2vh;text-align:center " v-if="items.observacion != '-'">
                {{ items.observacion }}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="390">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card class="pa-3">
        <v-select v-model="musica" :items="arrayMusica" menu-props="auto" hide-details label="Melodia"></v-select>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="naranja" label="naranja"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="rojo" label="rojo"></v-text-field>
          </v-col>
        </v-row>

        <v-checkbox dense v-model="progreso" label="Progreso"></v-checkbox>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="GrabaMelodia()">Graba</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogElimina" max-width="390">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogElimina = false">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card class="pa-3">
        <v-img class="mx-auto" height="80" width="80" src="/iconalerta.png"></v-img>
        <v-card-text>
          Este procedimiento mejora la rapidez en que llegan tus pedidos, Se recomienda hacerlo antes de
          iniciar tu Jornada o al finalizar!! Deseas hacerlo ahora?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="elimina()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <flotante @evento="evento($event)" />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  allBarra,
  eliminaBarra,
  grabaConfigura,
  grabareporteBarra,
  eliminaTotalBarra
} from '../../db'
import store from '@/store/index'
import pdf2base64 from 'pdf-to-base64'
import moment from 'moment'
import flotante from '@/components/botonflotante'
export default {

  components: {
    flotante
  },

  data() {
    return {
      barracolor: false,
      desserts: [],
      dialog: false,
      dialogElimina: false,
      arrayMusica: [
        "Campana", "Corneta"
      ],
      musica: '',
      tiempo: 0,
      progres: 0,
      naranja: '600',
      rojo: '1200',
      progreso: false
    }
  },
  mounted() {
    allBarra().orderByChild('fecha').on("value", this.onDataChange);
    this.musica = store.state.configuracion.melodia
    this.naranja = store.state.configuracion.naranja
    this.rojo = store.state.configuracion.rojo
    this.progreso = store.state.configuracion.progreso
    setInterval(this.actualizaTiempo, 1000);

  },
  beforeDestroy() {
    allBarra().off("value", this.onDataChange);
  },

  methods: {

    actualizaTiempo() {
      this.tiempo = moment().unix()

    },
    tempo(fecha) {
      var tempo = this.tiempo - fecha
      return this.secondsToString(tempo)
    },
    tempo2(fecha) {
      var tempo = (this.tiempo - fecha) / 15
      return tempo
    },
    tempo3(fecha) {
      var tempo = (this.tiempo - fecha)
      var color = "green"
      if (tempo > store.state.configuracion.naranja) {
        color = "orange"
      }
      if (tempo > store.state.configuracion.rojo) {
        color = "red"
      }
      return color
    },
    secondsToString(seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = (hour < 10) ? '0' + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = (minute < 10) ? '0' + minute : minute;
      var second = seconds % 60;
      second = (second < 10) ? '0' + second : second;
      return minute + ':' + second;
    },
    onDataChange(items) {
      let array = [];
      items.forEach((item) => {
        let key = item.key
        let data = item.val();
        data.uuid = key
        array.push(data)

      });
      this.filtramusica(store.state.configuracion.melodia)
      console.log(array)
      this.desserts = array
    },

    GrabaMelodia() {

      this.filtramusica(this.musica)
      grabaConfigura("melodia", this.musica)
      grabaConfigura("naranja", this.naranja)
      grabaConfigura("rojo", this.rojo)
      grabaConfigura("progreso", this.progreso)
      this.dialog = false
    },
    obtenTiempo(fecha) {
      return moment.unix(fecha).format("hh:mm:ss a")
    },
    completaPedido(item) {
      item.estado = "aprobado"
      item.fecha_atencion = moment().unix()
      grabareporteBarra(item).then((r) => {
        eliminaBarra(item.uuid)
      })
    },
    cancelaPedido(item) {
      item.estado = "cancelado"
      item.fecha_atencion = moment().unix()
      grabareporteBarra(item).then((r) => {
        eliminaBarra(item.uuid)
      })
    },
    filtramusica(value) {
      var melodias = ''
      if (value == 'Campana') {
        melodias = '/campana.mp3'
      } else {
        melodias = 'http://soundbible.com/mp3/Ta Da-SoundBible.com-1884170640.mp3'
      }
      this.playSound(melodias)
    },
    evento(item) {
      if (item == 1) {
        this.dialog = true
      }
      if (item == 2) {
        this.ira()
      }
      if (item == 3) {
        this.dialogElimina = true
      }
    },
    ira() {
      this.$router.push({
        path: '/reportecocinav3/'
      })
    },
    elimina() {
      eliminaTotalBarra()
      this.dialogElimina = false
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },

  }

}
</script>
