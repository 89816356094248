<template>
    <v-dialog v-model="dial" max-width="550" persistent>
        <div>
            <v-system-bar class="" dense window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>Habitacion : {{ data.nombre }}</h4>
                <v-spacer></v-spacer>
                <h4 class="">Tot: S/.{{ sumaTotal() }}</h4>
            </v-system-bar>
        </div>

        <v-card class="pa-3">


            <v-row class="mx-auto text-center">
                <v-col cols="4">
                    <v-icon @click="dial_catalogo = !dial_catalogo" color="orange" large>mdi-folder-open</v-icon>
                </v-col>
                <v-col cols="4">
                    <v-icon @click="dialogAgrega = !dialogAgrega" color="blue" large>mdi-plus</v-icon>
                </v-col>
                <v-col cols="4">
                    <v-icon @click="grabaComanda()" color="green" large>mdi-content-save</v-icon>
                </v-col>
            </v-row>
            <v-simple-table dense fixed-header height="55vh">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Nombre</th>
                            <th class="text-left">Observación</th>
                            <th class="text-left">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listaproductos" :key="item.uuid">
                            <td class="text-body-2">
                                <strong class="red--text">{{ item.cantidad }} x</strong> {{ item.nombre
                                }}
                                <v-icon color="green" class="ml-6" @click="abre_obs(item)">mdi-magnify</v-icon>
                                <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                    Gratuita</strong>
                            </td>
                            <td class="text-body-2">
                                <v-chip-group active-class="primary--text" column dense>
                                    <v-chip dense v-for="tag in item.observacion" :key="tag">{{ tag
                                        }}</v-chip>
                                </v-chip-group>
                            </td>
                            <td class="text-body-2">{{ (item.precio * item.cantidad).toFixed(2) }}
                                <v-icon color="red" class="ml-2" @click="editaProducto(item)">mdi-pencil</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-dialog v-model="dialogoProducto" max-width="390">
            <v-card>
                <v-system-bar window dark>
                    <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                </v-system-bar>
                <v-card-text>
                    <v-row class="mx-auto mt-4 text-center">
                        <v-col cols="4" xs="4">
                            <v-icon :disabled="!permite_editar" @click="suma()" color="green">mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="4" xs="4">
                            <v-text-field :disabled="!permite_editar" type="number" outlined v-model="cantidadEdita"
                                label="Cantidad"></v-text-field>
                        </v-col>
                        <v-col cols="4" xs="4">
                            <v-icon :disabled="!permite_editar" @click="resta()" color="red">mdi-minus</v-icon>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-btn :disabled="!permite_editar" color="red darken-1" text
                            @click="eliminaedita()">Eliminar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="grabaEdita()">Guardar</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <agrega_producto v-if="dialogAgrega" @cierra="dialogAgrega = false"
            @agrega_lista="agregaCatalogo($event), dialogAgrega = false" />
        <lista_observ v-if="dialog_prede" :data="item_selecto" @cierra="dialog_prede = false"
            @graba="graba_obs($event)" />
        <cateogrias v-if="dial_catalogo" @cierra="dial_catalogo = false" @array="agregaCatalogo($event)" />



        <v-dialog v-model="dialo_cantidad" max-width="200px">
            <v-card>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = !dialo_cantidad">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-title>
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @focus="$event.target.select()" @keyup.enter="agrega_con_cantidad()"></v-text-field>
                </v-card-title>
                <v-btn class="mt-n6" color="red" @click="agrega_con_cantidad()" block>OK</v-btn>
            </v-card>
        </v-dialog>

        </v-card>
    </v-dialog>
</template>

<script>
import {
    guard_registro,
    enviaCocina,
    enviaBarra,
} from '../../db'
import store from '@/store/index'
import moment from 'moment'
import cateogrias from '@/components/dialogos/dialogocatalogo'
import lista_observ from '@/views/restaurante/lista_observ'
import agrega_producto from '@/views/restaurante/agrega_producto'
import axios from "axios"
import {
    pdfprecuenta,
    pc_print,
    genera_precuenta
} from '../../pdf_precuentas'

export default {
    components: {
        cateogrias,
        lista_observ,
        agrega_producto
    },
    props: {
        data: []
    },
    data() {
        return {
            dial_catalogo: false,
            dialogAgrega: false,
            dialogoProducto: false,
            dialog_prede: false,
            listaproductos: [],
            cantidadEdita: '',
            observacion: '',
            permite_editar: false,
            item_selecto: '',
            categoriaselecta: '',
            buscar: '',
            activaproductos: false,
            dialo_cantidad: false,
            cantidad: '',
            producto_selecto: '',
            responsable: ''
        }
    },

    async created() {
        console.log(this.data)
        this.listaproductos = this.data.detalles.consumo || []
        this.dial = true
    },
    computed: {
        listafiltrada() {
            var invent = store.state.productos
            return invent.filter((item) =>
                (item.activo) == true)
                .filter((item) => (item.categoria)
                    .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                .filter((item) => (item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))

        }
    },
    methods: {
        agrega_con_cantidad() {
            if (this.cantidad == '') {
                this.cantidad = 1
            }
            this.dialo_cantidad = false
            this.producto_selecto.cantidad = this.cantidad
            this.buscar = ''
            this.agregaCatalogo(this.producto_selecto)
            this.$nextTick(() => {
                this.$refs.buscarField.focus();
            });
        },
        regresar() {
            this.activaproductos = false
        },
        iraproductos(item) {
            this.buscar = ''
            this.categoriaselecta = item.nombre
            if (this.listafiltrada != '') {
                this.activaproductos = true
            }

        },
        async grabaComanda() {
            store.commit("dialogoprogress")
            var lista_pendiente = this.listaproductos.filter(item => item.estado == 'pendiente')

            var array_cocina = lista_pendiente.filter(item => item.cocina == true)
            var array_barra = lista_pendiente.filter(item => item.barra == true)
            var array_cocina2 = lista_pendiente.filter(item => item.cocina2 == true)
            var array_barra2 = lista_pendiente.filter(item => item.barra2 == true)
            for (var i = 0; i < this.listaproductos.length; i++) {
                this.listaproductos[i].estado = 'enviado'
            }
            await Promise.all([
                guard_registro(this.data.detalles.fecha_entrada + '/consumo', this.listaproductos)
            ])

            if (store.state.configImpresora.impresoracocina || store.state.configImpresora.impresora_auto) {
                await this.envio_impresora(array_cocina, array_barra, array_cocina2, array_barra2)
            } else {
                await this.envio_pantallas(array_cocina, array_barra, array_cocina2, array_barra2)
            }
            store.commit("dialogoprogress")
            this.cierra()
        },
        api_rest(data, metodo) {
            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        async envio_pantallas(cocina, barra, cocina2, barra2) {
            if (cocina != '') {
                var arrayenvio_cocina = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.data.nombre,
                    data: cocina,
                    delivery: false
                }
                await enviaCocina(arrayenvio_cocina)
            }
            if (barra != '') {
                var arrayenvio_barra = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.data.nombre,
                    data: barra,
                    delivery: false
                }
                await enviaBarra(arrayenvio_barra)
            }
            return true
        },
        async envio_impresora(cocina, barra, cocina2, barra2) {
            if (cocina == '' && barra == '' && cocina2 == '' && barra2 == '') {
                return
            }
            var fecha = moment(String(new Date())).format('YYYY-MM-DD hh:mm')

            if (store.state.configImpresora.impresora_auto) {
                var array = {
                    delivery: false,
                    id_mesa: this.data.nombre,
                }
                var cocinas = await genera_precuenta(array, cocina, '80', false, this.responsable)
                var barras = await genera_precuenta(array, barra, '80', false, this.responsable)
                var nom_cocina = store.state.impresoras_restau.nom_cocina
                var nom_barra = store.state.impresoras_restau.nom_barra
                if (cocina != '') {
                    envia_host(cocinas, 'cocina.pdf', nom_cocina)
                    await new Promise(resolve => setTimeout(resolve, 350))
                }
                if (barra != '') {
                    envia_host(barras, 'barra.pdf', nom_barra)
                }
                if (cocina2 != '') {
                    envia_host(cocina2, 'cocina2.pdf', nom_cocina)
                    await new Promise(resolve => setTimeout(resolve, 350))
                }
                if (barra2 != '') {
                    envia_host(barra2, 'barra2.pdf', nom_barra)
                }

            } else {
                if (cocina != '') {
                    for (var i = 0; i < cocina.length; i++) {
                        cocina[i].observacion = cocina[i].observacion.toString()
                    }
                }
                if (barra != '') {
                    for (var i = 0; i < barra.length; i++) {
                        barra[i].observacion = barra[i].observacion.toString()
                    }
                }
                if (cocina2 != '') {
                    for (var i = 0; i < cocina2.length; i++) {
                        cocina2[i].observacion = cocina2[i].observacion.toString()
                    }
                }
                if (barra2 != '') {
                    for (var i = 0; i < barra2.length; i++) {
                        barra2[i].observacion = barra2[i].observacion.toString()
                    }
                }
                var a = await pc_print(this.data.nombre, this.responsable,
                    fecha, 0, cocina, barra, cocina2, barra2)
                console.log(a)
                return true
            }

        },
        sumaTotal() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precio)
            }
            return suma.toFixed(2)
        },
        abre_obs(val) {
            this.item_selecto = val
            this.dialog_prede = true
        },
        agregaCatalogo(value) {
            if (!Boolean(value.cocina2)) {
                value.cocina2 = false
            }
            if (!Boolean(value.barra2)) {
                value.barra2 = false
            }

            var arra = {
                uuid: this.create_UUID().substring(29),
                id: value.id,
                operacion: value.operacion,
                cantidad: value.cantidad,
                nombre: value.nombre,
                precio: value.precio,
                idmesa: this.data.nombre,
                estado: 'pendiente',
                observacion: value.observacion || '',
                cocina: value.cocina,
                barra: value.barra,
                cocina2: value.cocina2,
                barra2: value.barra2,
            }
            this.listaproductos.push(arra)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'RESTA',
                    array: arra
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
        },

        graba_obs(val) {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos[pos].observacion = val
            this.dialog_prede = false
        },
        editaProducto(val) {
            console.log(val)
            this.permite_editar = false
            if (val.estado == 'pendiente') {
                this.permite_editar = true
            } else {
                console.log(store.state.permisos.editar_comanda, !store.state.permisos.moduloempresa)
                if (!store.state.permisos.editar_comanda) {
                    this.permite_editar = false
                } else {
                    this.permite_editar = true
                }
            }

            this.item_selecto = val
            this.cantidadEdita = val.cantidad
            this.dialogoProducto = true
        },
        grabaEdita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            var invent = store.state.productos.find(item => item.id == this.item_selecto.id)
            if (store.state.configuracion.stock_comandas) {
                if (parseFloat(this.item_selecto.cantidad) > parseFloat(this.cantidadEdita)) {
                    var sum = parseFloat(this.item_selecto.cantidad) - parseFloat(this.cantidadEdita)
                    this.item_selecto.cantidad = sum
                    var array = {
                        metodo: 'SUMA',
                        array: this.item_selecto
                    }
                    this.api_rest(array, 'modifica_stock_unitario')
                } else {
                    var control = (this.cantidadEdita - this.item_selecto.cantidad)
                    var c = invent.stock - (control)
                    console.log(c)
                    if (c < 0) {
                        store.commit('dial_alert', "NO TIENE STOCK SUFICIENTE")
                        return
                    }
                    if (parseFloat(this.item_selecto.cantidad) < parseFloat(this.cantidadEdita)) {
                        var sum = parseFloat(this.cantidadEdita) - parseFloat(this.item_selecto.cantidad)
                        this.item_selecto.cantidad = sum
                        var array = {
                            metodo: 'RESTA',
                            array: this.item_selecto
                        }
                        this.api_rest(array, 'modifica_stock_unitario')
                    }
                }
            }
            this.listaproductos[pos].cantidad = this.cantidadEdita
            this.dialogoProducto = false
        },
        eliminaedita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos.splice(pos, 1)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'SUMA',
                    array: this.item_selecto
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
            this.dialogoProducto = false
        },
        suma() {
            this.cantidadEdita = parseInt(this.cantidadEdita) + 1
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        cierra() {
            this.$emit('cierra', false);
        }
    }
}
</script>

<style scoped>
.text-h4 {
    font-size: 2rem;
}

.text-h5 {
    font-size: 1.5rem;
}

.pa-3 {
    padding: 1rem;
}

.elevation-6 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
