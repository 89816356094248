<template>
  <div class="pa-4">
    <v-row dense>
      <v-col cols="6" sm="3">
        <v-text-field class="mx-1" outlined dense type="date" v-model="date" label="Inicio" />
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin" />
      </v-col>
      <v-col cols="6" sm="3">
        <v-select dense v-model="estado" :items="array_estados" label="Estado" outlined />
      </v-col>
      <v-col cols="6" sm="3">
        <v-select dense v-model="reporte" :items="array_reporte" label="Reporte" outlined />
      </v-col>
    </v-row>

    <v-card>
      <v-row class="mx-auto">
        <v-col cols="6">
          <v-btn small block :color="tablaconsolida ? 'primary' : 'success'" @click="toggleConsolidado">
            {{ tablaconsolida ? 'Detallado' : 'Consolida' }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-icon class="mr-6" large @click="exporta" color="green">mdi-microsoft-excel</v-icon>
          <v-icon large @click="filtrar" color="info">mdi-filter</v-icon>
        </v-col>
      </v-row>

      <v-simple-table v-if="tablaconsolida" fixed-header height="70vh" dense>
        <thead>
          <tr>
            <th>Cant</th>
            <th class="text-left">Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in arrayConsolidar" :key="item.id">
            <td>{{ item.cantidad }}</td>
            <td>{{ item.nombre }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-simple-table v-else fixed-header height="70vh" dense>
        <thead>
          <tr>
            <th>Cant</th>
            <th class="text-left">Nombre</th>
            <th class="text-left">Fecha</th>
            <th class="text-left">Cliente</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listafiltrada" :key="item.id" @click="mostrarDetalle(item)">
            <td>{{ item.cantidad }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ conviertefecha(item.fecha) }}</td>
            <td>{{ item.delivery ? 'Delivery: ' : 'Mesa: ' }}{{ item.cliente }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="dialogo" max-width="300">
      <v-card>
        <v-system-bar window dark>
          <v-icon @click="dialogo = false">mdi-close</v-icon>
        </v-system-bar>
        <v-card-title>Detalle de pedido</v-card-title>
        <v-card-text>
          <p>Atendido por: <span class="red--text">{{ itemselecto.usuario }}</span></p>
          <p>Observación : <span class="red--text">{{ itemselecto.observacion }}</span></p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import store from '@/store/index'
import XLSX from 'xlsx';
import { allreporteCocina, EliminareporteCocina, allreporteBarra, EliminareporteBarra } from '../../db';

export default {
  data: () => ({
    array_reporte: ['cocina', 'barra'],
    reporte: 'cocina',
    array_estados: ['aprobado', 'cancelado'],
    estado: 'aprobado',
    tablaconsolida: false,
    itemselecto: {},
    dialogo: false,
    desserts: [],
    arrayConsolidar: [],
    date: moment().format('YYYY-MM-DD'),
    date2: moment().format('YYYY-MM-DD')
  }),

  created() {
    this.filtrar();
  },

  computed: {
    listafiltrada() {
      return this.desserts;
    }
  },

  methods: {
    async filtrar() {
      try {
        store.commit("dialogoprogress", true);

        const start = moment(this.date).startOf('day').unix();
        const end = moment(this.date2).endOf('day').unix();
        const obtenerReporte = this.reporte === 'cocina' ? allreporteCocina : allreporteBarra;

        const snap = await obtenerReporte()
          .orderByChild('fecha')
          .startAt(start)
          .endAt(end)
          .once("value");


        const array = [];

        snap.forEach(snapItem => {
          const data = snapItem.val();
          if (data.estado !== this.estado) return;

          const pedidos = data.data || [];
          pedidos.forEach(p => {
            array.push({
              ...p,
              cliente: data.idmesa || 'S/N',
              fecha: data.fecha || 0,
              estado: data.estado || '',
              usuario: data.usuario || '',
            });
          });
        });

        this.desserts = array;

      } catch (error) {
        console.error("Error al filtrar datos:", error);
        store.commit("dialogosnackbar", "Error al cargar el reporte.");
      } finally {
        store.commit("dialogoprogress", false);
      }
    },


    conviertefecha(date) {
      return moment.unix(date).format('DD/MM hh:mm A');
    },

    toggleConsolidado() {
      if (!this.tablaconsolida) {
        this.cargaData().then(() => this.tablaconsolida = true);
      } else {
        this.tablaconsolida = false;
        this.arrayConsolidar = [];
      }
    },

    async cargaData() {
      this.arrayConsolidar = [];
      this.desserts.forEach(item => {

        const existente = this.arrayConsolidar.find(p => p.id === item.id);
        if (existente) {
          existente.cantidad += parseInt(item.cantidad);
        } else {
          this.arrayConsolidar.push({
            id: item.id,
            nombre: item.nombre,
            cantidad: parseInt(item.cantidad)
          });
        }

      });
      return true;
    },

    exporta() {
      const data = XLSX.utils.json_to_sheet(this.desserts);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, "REPORTE");
      XLSX.writeFile(workbook, `REPORTE_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    },

    mostrarDetalle(item) {
      this.itemselecto = item;
      this.dialogo = true;
    },

    Borradata() {
      if (this.$route.params.id === 'cocina') {
        EliminareporteCocina();
      } else {
        EliminareporteBarra();
      }
      this.dialogoElimina = false;
    }
  }
};
</script>

<style scoped>
.v-icon {
  cursor: pointer;
}
</style>
