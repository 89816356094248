<template>
    <v-dialog v-model="dialog" max-width="500">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierre()">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-row class="pa-2">
                <v-col cols="6">
                    <v-btn color="primary" x-small block dark @click="dialog_crear = true">Crear Mesa </v-btn>
                    <v-btn v-if="false" class="mt-2" color="success" x-small block dark>Crear Zona </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-select outlined offset-y dense v-model="zona" :items="array_zonas" hide-details
                        label="ZONA "></v-select>
                </v-col>
            </v-row>
            <v-simple-table fixed-header height="400px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Mesa
                            </th>
                            <th class="text-left">
                                Zona
                            </th>
                            <th class="text-left">
                                Nota
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in mesas_filtradas" :key="item.id_mesa">
                            <td>{{ item.nom_mesa }}</td>
                            <td>{{ item.zona_mesa }}</td>
                            <td>{{ item.nota_mesa }}</td>
                            <td width="150">
                                <v-row>
                                    <v-col cols="4">
                                        <v-icon color="green" @click="editar_mesa(item)">mdi-lead-pencil</v-icon>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-icon color="red" @click="eliminar_mesa(item)">mdi-delete</v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dialog_crear" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog_crear = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mt-n1">
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="nom_mesa" label="Numero Mesa"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select outlined offset-y dense v-model="zona_mesa" :items="array_zonas_f" hide-details
                            label="ZONA "></v-select>
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                        <v-text-field outlined dense v-model="nota_mesa" label="Nota Adicional"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dense class="mt-n4" color="green darken-1" text @click="crear_mesa()">
                        crear
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    nuevaMesa,
    allMesa,
    eliminaMesa
} from '../../db'

import moment from 'moment'
import store from '@/store/index'
export default {

    data() {
        return {
            dialog: false,
            array_zonas: ["TODAS", "ZONA 1", "ZONA 2", "ZONA 3", "ZONA 4", "TERRAZA", "SALON", "CAMPO"],
            array_zonas_f: ["ZONA 1", "ZONA 2", "ZONA 3", "ZONA 4", "TERRAZA", "SALON", "CAMPO"],
            zona: 'TODAS',
            array_mesas: [],
            dialog_gestionar: false,
            dialog_crear: false,
            nom_mesa: '',
            zona_mesa: "ZONA 1",
            nota_mesa: '',
            editar: ''
        }
    },
    mounted() {
        allMesa().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allMesa().off("value", this.onDataChange);
    },
    created() {
        this.dialog = true
    },
    computed: {
        mesas_filtradas() {
            var filtro = this.array_mesas
            if (this.zona == 'TODAS') {
                return filtro
            } else {
                return filtro.filter(item => item.zona_mesa == this.zona)
            }
        },
    },
    methods: {
        onDataChange(items) {
            this.array_mesas = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_mesas = array;
        },
        async crear_mesa() {
            if (!this.editar && this.array_mesas.find(item => item.id_mesa == this.nom_mesa)) {
                store.commit('dial_alert', 'NUMERO DE MESA YA EXISTE')
                return
            }
            store.commit("dialogoprogress")
            var array = {
                id_mesa: this.nom_mesa,
                nom_mesa: this.nom_mesa,
                zona_mesa: this.zona_mesa,
                nota_mesa: this.nota_mesa,
                estado: 'PENDIENTE'
            }
            await nuevaMesa(array.id_mesa, array)
            this.nom_mesa = ''
            this.zona_mesa = 'ZONA 1'
            this.nota_mesa = ''
            this.editar = false
            this.dialog_crear = false
            store.commit("dialogoprogress")
        },
        editar_mesa(val) {
            this.editar = true
            this.nom_mesa = val.nom_mesa
            this.zona_mesa = val.zona_mesa
            this.nota_mesa = val.nota_mesa
            this.dialog_crear = true
        },
        async eliminar_mesa(val) {
            if (confirm('SEGURO DE ELIMINAR MESA ' + val.nom_mesa)) {
                store.commit("dialogoprogress")
                await eliminaMesa(val.id_mesa)
                store.commit("dialogoprogress")
            }
        },
        cierre() {
            this.$emit('cierre', false)
        }

    }

}
/*
 */
</script>
