<template>
    <div class="pa-3">
        <v-row dense class="mb-2">
            <v-col cols="6">
                <h3>Detalles Generales</h3>
            </v-col>
            <v-col cols="6">
                <v-btn rounded elevation="6" block color="info" @click="modo = 'general', dial_crea_grupo = true"
                    x-small>
                    <v-icon left>
                        mdi-plus
                    </v-icon>Nuevo
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="pa-1">
            <v-data-table :headers="[{
                text: 'GRUPO',
                value: 'nombre'
            },
            {
                text: 'Categorias',
                value: 'categoria'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ]" :items="listafiltrada_gen" item-key="nombre" dense show-expand :expanded.sync="expanded2">
                <template v-slot:item.categoria="{ item }">
                    <v-row>

                        <v-col cols="12"> {{ item.categorias }}
                            <v-icon color="error" @click="agrega_cat(item)">mdi-flag</v-icon>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="6">
                            <v-icon color="green" @click="agrega(item)">mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="6">
                            <v-icon color="red" @click="elimina_grupo(item)">mdi-delete</v-icon>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-2">
                        <v-simple-table fixed-header dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="items in item.lista" :key="items.id">
                                        <td>{{ items.nombre }}</td>
                                        <td>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-icon color="red"
                                                        @click="elimina_obs(items, item)">mdi-delete</v-icon>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
        </v-card>
        <v-row dense class="mt-2 mb-2">
            <v-col cols="6">
                <h3>Detalles x Plato</h3>
            </v-col>
            <v-col cols="6">
                <v-btn rounded elevation="6" block color="success" @click="modo = 'unitario', dial_crea_grupo = true"
                    x-small>
                    <v-icon left>
                        mdi-plus
                    </v-icon>Nuevo
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="pa-1">
            <v-data-table :headers="dessertHeaders" :items="listafiltrada" item-key="nombre" dense show-expand
                :expanded.sync="expanded">
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="6">
                            <v-icon color="green" @click="agrega(item)">mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="6">
                            <v-icon color="red" @click="elimina_grupo(item)">mdi-delete</v-icon>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-2">
                        <v-simple-table fixed-header dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="items in item.lista" :key="items.id">
                                        <td>{{ items.nombre }}</td>
                                        <td>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-icon color="red"
                                                        @click="elimina_obs(items, item)">mdi-delete</v-icon>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dial_crea_grupo" max-width="400" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_crea_grupo = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-1">
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-text-field placeholder="CREMAS" outlined dense v-model="nom_grupo"
                            label="Nombre Grupo"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n8">
                    <v-spacer></v-spacer>
                    <v-btn color="deep-purple lighten-2" text @click="crea_grupo(modo)">
                        Agrega
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_agrega_obs" max-width="400" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_agrega_obs = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-1">
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-text-field placeholder="AJI" outlined dense v-model="nom_obs"
                            label="Nombre Observacion"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n8">
                    <v-spacer></v-spacer>
                    <v-btn color="deep-purple lighten-2" text @click="agrega_obs()">
                        Agrega
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_cate" max-width="400" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_cate = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-1">
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-select v-model="categoriasSeleccionadas" :items="$store.state.categorias" item-text="nombre"
                            item-value="nombre" label="Seleccionar Categorías" multiple chips clearable
                            menu-props="auto" outlined dense rows="2"></v-select>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n8">
                    <v-spacer></v-spacer>
                    <v-btn color="deep-purple lighten-2" text @click="agregar_cat()">
                        guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios"
import store from '@/store/index'
import {
    all_grupo_obs,
    nuevo_grupo_obs,
    edita_campo_grupo_obs,
} from '../../db'
export default {
    props: {
        dial_activo: '',
    },
    name: 'caja',
    data() {
        return {
            expanded: [],
            singleExpand: false,
            expanded2: [],
            singleExpand2: false,
            dessertHeaders: [{
                text: 'GRUPO',
                value: 'nombre'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ],
            dial_crea_grupo: false,
            dial_agrega_obs: false,
            dial_cate: false,
            desserts: [],
            nom_grupo: '',
            nom_obs: '',
            grupo_selecto: [],
            modo: 'general',
            categoriasSeleccionadas: [],
        }
    },
    computed: {
        listafiltrada() {
            this.desserts = store.state.observaciones;
            return this.desserts.filter(item => item.modo != "general");
        },
        listafiltrada_gen() {
            this.desserts = store.state.observaciones;
            return this.desserts.filter(item => item.modo === "general");
        }

    },
    methods: {
        async agregar_cat() {
            store.commit("dialogoprogress")
            await edita_campo_grupo_obs(this.grupo_selecto.id, 'categorias', this.categoriasSeleccionadas)
            this.dial_cate = false
            store.commit("dialogoprogress")
        },
        agrega_cat(val) {
            console.log(store.state.categorias)
            this.grupo_selecto = val
            this.dial_cate = true
        },
        async crea_grupo(modo) {
            if (this.nom_grupo == '') {
                store.commit('dial_alert', 'no puede ser vacio')
                return
            }
            this.desserts.push({
                id: this.desserts.length,
                nombre: this.nom_grupo.toUpperCase(),
                modo: modo
            })
            await nuevo_grupo_obs(this.desserts)
            this.nom_grupo = ''
            this.dial_crea_grupo = false
        },
        elimina(data) {
            var pos = this.desserts.map(e => e.id).indexOf(data.id)
            this.desserts.splice(pos, 1)
            nuevo_grupo_obs(this.desserts)
        },
        cierra() {
            this.$emit('cierra', false)
        },
        agrega(data) {
            this.grupo_selecto = data
            this.dial_agrega_obs = true
        },
        async agrega_obs() {
            if (this.nom_obs == '') {
                store.commit('dial_alert', 'no puede ser vacio')
                return
            }
            store.commit("dialogoprogress")
            var data = this.grupo_selecto
            var array = []
            if (this.grupo_selecto.lista != undefined) {
                array = this.grupo_selecto.lista
            }
            array.push({
                nombre: this.nom_obs.toUpperCase()
            })
            await edita_campo_grupo_obs(data.id, 'lista', array)
            this.nom_obs = ''
            this.dial_agrega_obs = false
            store.commit("dialogoprogress")
        },
        async elimina_grupo(val) {
            if (confirm('seguro que desea eliminar?')) {
                this.desserts.splice(val.id, 1)
                await nuevo_grupo_obs(this.desserts)
            }
        },
        async elimina_obs(data, item) {
            if (confirm('seguro que desea eliminar?')) {
                var pos = this.desserts[item.id].lista.map(e => e.nombre).indexOf(data.nombre)
                this.desserts[item.id].lista.splice(pos, 1)
                await nuevo_grupo_obs(this.desserts)
            }
        }
    },

}
</script>
