<template>
    <div class="pa-3">
        <v-row class="mx-auto text-center">
            <v-col cols="1">
                <v-icon @click="regresa()" color="red" large>mdi-keyboard-return</v-icon>
            </v-col>
            <v-col cols="3">
                <v-btn color="success" small rounded elevation="15" block
                    @click="obtenordenproducto(), contador = true">NUEVA
                    RECETA</v-btn>
            </v-col>

        </v-row>


        <v-dialog v-model="dialogo" max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="guardareceta()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="elimina()">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row class="mt-n2">
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field dense disabled v-model="id" label="ID"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field type="number" dense v-model="idproducto" label="PRODUCTO"
                            append-icon="mdi-magnify" @click:append="dial_catalogo = !dial_catalogo"
                            @keyup.enter="dial_catalogo = !dial_catalogo"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n7">
                    <v-col cols="12">
                        <v-textarea disabled dense outlined v-model="nombreproducto" auto-grow filled
                            label="Descripcion" rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-card-title primary-title class="mt-n12">
                    INSUMOS
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogoagregainsumos = !dialogoagregainsumos" color="green">mdi-plus</v-icon>
                </v-card-title>
                <v-simple-table fixed-header height="300px" dense>
                    <thead>

                        <tr>
                            <th class="text-left">
                                Insumo
                            </th>
                            <th class="text-left">
                                Medida
                            </th>
                            <th class="text-left">
                                Cantidad
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in insumos" :key="item.id" @click="abreinsumoedita(item)">
                            <td>{{ item.nombreinsumo }}</td>
                            <td>{{ item.medida }}</td>
                            <td>{{ item.cantidadinsumo }}</td>
                        </tr>
                    </tbody>

                </v-simple-table>

                <cateogrias v-if="dial_catalogo" @array="seleccionaproducto($event)" @cierra="dial_catalogo = false" />
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoagregainsumos" max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogoagregainsumos = !dialogoagregainsumos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="saveinsumo()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="eliminainsumo()">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row class="mt-n2">
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field type="number" dense v-model="idinsumo" label="INSUMO" append-icon="mdi-magnify"
                            @click:append="$store.commit('dialogoinsumo')"
                            @keyup.enter="$store.commit('dialogoinsumo')"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field dense type="number" v-model="cantidadinsumo"
                            label="Cantidad insumo"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n7">
                    <v-col cols="12">
                        <v-textarea disabled dense outlined v-model="nombreinsumo" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
            </v-card>
            <dialogoinsumos @array="seleccionainsumo($event)" />
        </v-dialog>

        <v-card class="mt-3">

            <v-simple-table fixed-header height="65vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Orden
                            </th>
                            <th class="text-left">
                                Receta para:
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.nombreproducto }}</td>
                            <td>
                                <v-row>
                                    <v-col cols="12">
                                        <v-icon color="green" @click.prevent="editareceta(item)">mdi-pencil</v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

    </div>
</template>

<script>
import {
    obtenContador,
    nuevoReceta,
    sumaContador,
    allReceta,
    elimiia_Receta
} from '../../db'
import cateogrias from '@/components/dialogos/dialogocatalogo'
import dialogoinsumos from '@/components/dialogos/dialogoinsumos'
import store from '@/store/index'

export default {
    components: {
        cateogrias,
        dialogoinsumos
    },

    data: () => ({
        dial_catalogo: false,
        dialogo: false,
        dialogoagregainsumos: false,
        dialogoElimina: false,
        activo: true,
        id: '',
        idproducto: '',
        nombreproducto: '',
        insumos: [],
        idinsumo: '',
        medidainsumo: '',
        nombreinsumo: '',
        cantidadinsumo: '',
        itemselecto: [],
        desserts: [],
        contador: true
    }),
    mounted() {
        allReceta().on("value", this.onDataChange);
        // allCabecera().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allReceta().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            allReceta().on("value", this.onDataChange);
            return this.desserts
        }
    },
    methods: {
        async elimina() {
            store.commit("dialogoprogress")
            await elimiia_Receta(this.id)
            this.dialogo = false
            store.commit("dialogoprogress")
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push({
                    id: data.id,
                    idproducto: data.idproducto,
                    nombreproducto: data.nombreproducto,
                    data: data.arrayinsumos
                });

            });

            this.desserts = array;
        },
        seleccionaproducto(value) {
            this.idproducto = value.id
            this.nombreproducto = value.nombre
        },
        seleccionainsumo(value) {
            this.idinsumo = value.id
            this.nombreinsumo = value.nombre
            this.medidainsumo = value.medida
        },
        saveinsumo() {
            if (this.idinsumo == '' || this.idproducto == '' || this.cantidadinsumo == '') {
                store.commit('dialogosnackbar', "Falta Datos")
            } else {
                this.insumos.push({
                    idinsumo: this.idinsumo,
                    nombreinsumo: this.nombreinsumo,
                    cantidadinsumo: this.cantidadinsumo,
                    medida: this.medidainsumo
                })
                this.dialogoagregainsumos = false
                this.idinsumo = ''
                this.nombreinsumo = ''
                this.cantidadinsumo = ''
            }
        },
        abreinsumoedita(array) {
            this.itemselecto = array
            this.idinsumo = array.idinsumo
            this.nombreinsumo = array.nombreinsumo
            this.cantidadinsumo = array.cantidadinsumo
            this.dialogoagregainsumos = true
        },
        obtenordenproducto() {
            store.commit("dialogoprogress")
            obtenContador().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    this.limpiacajas()
                    this.id = snapshot.val().ordenrecetas
                    this.dialogo = true
                }
                store.commit("dialogoprogress")
            })
        },
        guardareceta() {

            var array = {
                id: this.id,
                idproducto: this.idproducto,
                nombreproducto: this.nombreproducto,
                arrayinsumos: this.insumos
            }
            nuevoReceta(this.id, array).then((r) => {
                this.dialogo = false
                this.sumacontador(this.id)
                this.limpiacajas()
            })

        },
        limpiacajas() {
            this.id = ''
            this.idproducto = ''
            this.nombreproducto = ''
            this.insumos = []
        },
        editareceta(value) {
            this.id = value.id
            this.idproducto = value.idproducto
            this.nombreproducto = value.nombreproducto
            this.insumos = value.data
            this.contador = false
            this.dialogo = true
        },
        sumacontador(id) {
            if (this.contador) {
                sumaContador("ordenrecetas", (parseInt(id) + 1).toString().padStart(4, 0))
            }

        },
        eliminainsumo() {
            for (var i = 0; i < this.insumos.length; i++) {
                if (this.insumos[i].idinsumo == this.itemselecto.idinsumo) {
                    this.insumos.splice(i, 1)
                }

            }

            this.dialogoagregainsumos = false
        },
        regresa() {
            this.$router.push({
                name: 'costeo_receta'
            })
        }
    },
}
</script>
