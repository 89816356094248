<template>
    <div>
        <v-card class="elevation-6">
            <v-autocomplete v-if="!activaproductos" v-model="producto_sele" :items="$store.state.productos"
                item-text="nombre" item-value="id" label="Buscar Productos" clearable menu-props="auto" outlined dense
                ref="buscarField" :autofocus="!$store.state.esmovil" append-icon="mdi-magnify" @change="prod_selecto">
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>{{ item.categoria }}</strong> - {{ item.nombre }} - <strong class="red--text">S/ {{
                                item.precio }}</strong>
                        </v-list-item-title>
                    </v-list-item-content>
                </template></v-autocomplete>

            <v-simple-table v-if="!activaproductos" fixed-header class="pa-1 mt-n3"
                style="height: calc(80vh - 80px); overflow-y: auto;">
                <template v-slot:default>
                    <thead></thead>
                    <tbody>
                        <v-row class="mt-1 mb-4 mx-auto text-center" dense>
                            <v-col v-for="item in $store.state.categorias" :key="item.id" cols="6" class="pa-1">
                                <v-card class="hoverable" @click.prevent="iraproductos(item)"
                                    style="height: 65px; overflow: hidden;">
                                    <v-card-text class="text-center"
                                        style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                                        <span class="text-body-5 font-weight-medium">{{ item.nombre }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </tbody>
                </template>
            </v-simple-table>
            <div v-if="activaproductos">
                <v-card-title>
                    <v-btn icon class="ml-2 mt-n1" @click="regresar">
                        <v-icon color="red">mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field ref="buscarField" class="mb-n1 mt-n2" outlined dense v-model="buscar"
                        append-icon="mdi-magnify" label="Buscar" single-line hide-details
                        :autofocus="!$store.state.esmovil"></v-text-field>
                </v-card-title>
                <v-simple-table fixed-header height="65vh" dense class="elevation-6">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Stock</th>
                                <th class="text-left">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id" @click="prod_selecto2(item)">
                                <td class=" text-body-2">{{ item.nombre }}</td>
                                <td class="text-body-2">{{ item.stock }}</td>
                                <td class="text-body-2">{{ item.precio }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-card>

        <v-dialog v-model="dialo_cantidad" max-width="350px">
            <v-card>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-title>
                    <v-textarea v-model="observacion_can" auto-grow filled color="deep-purple" label="Observacion"
                        rows="1"></v-textarea>
                    <v-chip-group column multiple v-model="observacionesSeleccionadas"
                        active-class="deep-purple--text text--accent-4">
                        <v-chip v-for="(item, index) in obs_gen" :key="index" filter outlined>
                            {{ item.nombre }}
                        </v-chip>
                    </v-chip-group>
                </v-card-title>
                <v-card-text class="mt-n4">
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @focus="$event.target.select()" @keydown.enter="agrega_con_cantidad()">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mt-n6" color="red" @click="agrega_con_cantidad()" block>OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
export default {
    name: 'caja',

    props: {
        data: [],
    },
    data() {
        return {
            activaproductos: false,
            producto_sele: '',
            buscar: '',
            cantidad: '',
            producto_selecto: '',
            dialo_cantidad: false,
            obs_gen: [],
            observacionesSeleccionadas: [],
            observacion_can: '',
            categoriaselecta: '',
        }
    },
    computed: {
        listafiltrada() {
            var invent = store.state.productos
            return invent.filter((item) =>
                (item.activo) == true)
                .filter((item) => (item.categoria)
                    .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                .filter((item) => (item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))

        }
    },


    methods: {
        enfocarInput() {
            this.$nextTick(() => {
                this.$refs.buscarField.focus(); // Método para enfocar el campo
            })
        },
        agrega_con_cantidad() {
            if (this.cantidad == '') {
                this.cantidad = 1
            }
            this.dialo_cantidad = false
            this.producto_selecto.cantidad = this.cantidad

            let observacionesNombres = this.observacionesSeleccionadas.map(index => {
                const obs = this.obs_gen[index]; // Buscar el nombre en `obs_gen`
                return obs ? obs.nombre : index; // Si encuentra, usa el nombre; si no, deja el valor original
            });
            this.producto_selecto.observacion = this.observacion_can ? [this.observacion_can, ...observacionesNombres] : [...observacionesNombres];
            this.buscar = ''
            this.$emit('agrega_lista', this.producto_selecto)
            if (this.producto_selecto.es_combo) {
                for (var i = 0; i < this.producto_selecto.lista_combo.length; i++) {
                    var dd = this.producto_selecto.lista_combo[i]
                    const producto = store.state.productos.find(p => p.id == dd.cod_producto);
                    if (producto) {
                        let productoClonado = { ...producto }; // ✅ Clonar el objeto

                        productoClonado.operacion = 'GRATUITA';
                        productoClonado.cantidad = dd.cantidad * this.cantidad;
                        productoClonado.precio = 1;
                        productoClonado.observacion = '';

                        this.$emit('agrega_lista', productoClonado);
                    }
                }
            }

            this.$nextTick(() => {
                this.observacionesSeleccionadas = [];
                this.observacion_can = ''
                this.$refs.buscarField.focus();

            });
        },
        prod_selecto(valor) {
            if (!valor) return; // Si no hay valor seleccionado, no hacer nada
            this.$nextTick(() => {
                this.producto_sele = "";
            });
            // Buscar el producto seleccionado en la lista del store
            const producto = store.state.productos.find(p => p.id === valor);
            if (producto) {
                // Filtrar las observaciones disponibles
                const obs_filt = store.state.observaciones.filter(e =>
                    e.modo == 'general' && e.categorias.includes(producto.categoria)
                );
                this.obs_gen = obs_filt.length > 0 ? obs_filt.flatMap(e => e.lista) : [];
                this.observacionesSeleccionadas = [];
                this.observacion_can = ''
                this.cantidad = 1;
                this.producto_selecto = producto;
                this.dialo_cantidad = true;
            }
        },
        prod_selecto2(valor) {
            if (!valor) return; // Si no hay valor seleccionado, no hacer nada
            this.$nextTick(() => {
                this.producto_sele = "";
            });
            // Buscar el producto seleccionado en la lista del store
            if (valor) {
                // Filtrar las observaciones disponibles
                const obs_filt = store.state.observaciones.filter(e =>
                    e.modo == 'general' && e.categorias.includes(valor.categoria)
                );
                this.obs_gen = obs_filt.length > 0 ? obs_filt.flatMap(e => e.lista) : [];
                this.observacionesSeleccionadas = [];
                this.observacion_can = ''
                this.cantidad = 1;
                this.producto_selecto = valor;
                this.dialo_cantidad = true;
            }
        },
        iraproductos(item) {
            this.buscar = ''
            this.categoriaselecta = item.nombre
            if (this.listafiltrada != '') {
                this.activaproductos = true
            }

        },
        regresar() {
            this.activaproductos = false
        },
    },

}
</script>
